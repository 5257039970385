import React from 'react'

const Privacy = () => {
  return (
    <div className="flex-1 text-snow" style={{padding: '10rem'}}>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h3 className="text-2xl font-bold mb-4">Privacy Policy for Mingo Ventures LLC Privacy Policy</h3>
        <p className="text-gray-300 mb-6">Last updated: 10 February 2022</p>

        <div className="space-y-8">
          <h6 className="text-xl font-bold mt-8 mb-4">Preamble</h6>
          <p className="mb-4">
            In the following privacy policy, you will ﬁnd everything you need to
            know about how your data will be processed by: Mingo Ventures LLC
          </p>
          <p className="mb-4">This Privacy Policy describes our policies and procedures.</p>
          <p className="mb-4">
            We use your Personal Data to provide and improve the Services. By
            using the Services, you agree to the collection and use of information
            in accordance with this Privacy Policy.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Consent</h6>
          <p className="mb-4">
            Under data protection laws, we are required to provide you with
            certain information about who we are, how we process your personal
            data and for what purpose. We are also obliged to out- line your
            rights in relation to your personal data.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Interpretation and Deﬁnitions Interpretation</h6>
          <p className="mb-4">
            In this policy, save where the context requires, the following
            capitalised terms shall have respective meanings ascribed to them as
            follows:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>“Account” </strong>means a unique account
              created for you to access our Service
            </li>
            <li>
              <strong>“Company” </strong>(referred to as either
              “the Company”, “We”, “Us” or “Our” in this Agreement) refers to
              Mingo Ventures LLC, First Floor, SVG Teachers Credit Union Uptown
              Building, Corner of James &amp; Middle Street Kingston O. St.
              Vincent &amp; the Grenadines.
            </li>
            <li>
              <strong>“Cookies” </strong>are small ﬁles that are placed on Your
              computer, mobile device or any other device by a website, containing
              the details of Your browsing history on that website among its many
            </li>
            <li>
              <strong>“Country” </strong>refers to: Saint Vincent
              and the
            </li>
            <li>
              <strong>“Device” </strong>means any device that can
              access the Services such as a computer, a cellphone or a digital
            </li>
            <li>
              <strong>“Personal Data” </strong>is any information
              that relates to an identiﬁed or identiﬁable
            </li>
            <li>
              <strong>“Services” </strong>refers to the Website and mobile
              application.
            </li>
            <li>
              <strong>“Service Provider” </strong>means any
              natural or legal person who processes the data on behalf of the It
              refers to third-party companies or individuals employed by the Com-
              pany to facilitate the Services, to provide the Services on behalf
              of the Company, to per- form functions related to the Services or to
              assist the Company in analyzing how the Services are used.
            </li>
            <li>
              <strong>“Third-Party” </strong>refers to any
              website and/or any social media websites through which a user can
              log in or create an account to use the Services.
            </li>
            <li>
              <strong>“Usage Data“ </strong>refers to data
              collected automatically, either generated by the use of the Services
              or from the Services infrastructure itself (for example, the
              duration of a page visit).
            </li>
            <li>
              <strong>“Website” </strong>refers to Mingo,
              accessible from <u>https://</u
              ><a href="http://www.mingoapps.com/">mingoapps.com </a>and
              <u>tyson- furycollection.com</u>
            </li>
          </ul>
          <h6 className="text-xl font-bold mt-8 mb-4">Location Data</h6>
          <p className="mb-4">
            Mingo Ventures LLC is committed to protecting your personal data and
            respecting the privacy of all users.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Introduction</h6>
          <p className="mb-4">
            This policy together with any additional terms of use incorporated by
            reference of our Services will apply to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              Mingo website software (<strong>Website</strong>) and any mobile
              application hosted on the Apple AppStore, once you have downloaded
              or streamed a copy of the App onto your mobile telephone or handheld
              device (<strong>Device</strong>).
            </li>
            <li>
              Any of the services accessible through our Website or App
              (<strong>Services</strong>). This policy sets out the basis on which
              any personal data we collect from you, or that you provide to us,
              will be processed by us. Our Services are not intended for children,
              and we do not knowingly collect data relating to children. Please
              read the following carefully to understand our practices regarding
              your personal data and how we will treat your data
            </li>
          </ul>
          <p className="mb-4">
            We also collect, use and share Aggregated Data such as statistical or
            demographic data for any purpose. Aggregated Data could be derived
            from your personal data but is not considered per- sonal data in law
            as this data will not directly or indirectly reveal your identity. For
            example, we may aggregate your Usage Data to calculate the percentage
            of users accessing a speciﬁc App or website feature. However, if we
            combine or connect Aggregated Data with your personal data so that it
            can directly or indirectly identify you, we treat the combined data as
            personal data which will be used in accordance with this privacy
            policy.
          </p>
          <p className="mb-4">
            We do not collect any Special Categories of Personal Data about you
            (this includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health, and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and oﬀences.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">How we use your Personal Data</h6>
          <p className="mb-4">
            We will only use your personal data when the law allows us to do so.
            Most commonly we will use your personal data in the following
            circumstances:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Where you have consented before the processing.</li>
            <li>
              Where we need to perform a contract, we are about to enter or have
              entered with
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those
            </li>
            <li>Where we need to comply with a legal or regulatory</li>
          </ul>
          <p className="mb-4">
            We will only send you direct marketing communications by email or text
            if we have your con- sent. You have the right to withdraw that consent
            at any time by contacting us.
          </p>
          <p className="mb-4">
            We will get your express opt-in consent before we share your personal
            data with any third party for marketing purposes.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Disclosures of your personal data</h6>
          <p className="mb-4">
            When you consent to providing us with your personal data, we will also
            ask you for your consent to share your personal data with any
            Third-Party.
            <em>Purposes for which we will use your personal data</em>:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Internal Third Parties</li>
            <li>External Third Parties</li>
            <li>
              Third parties to whom we may choose to sell, transfer or merge parts
              of our business or our assets. Alternatively, we may seek to acquire
              other businesses or merge with them. If a change happens to our
              business, then the new owners may use your personal data in the same
              way as set out in this privacy policy
            </li>
          </ul>
          <h6 className="text-xl font-bold mt-8 mb-4">Important information and who we are</h6>
          <p className="mb-4">
            The controller as deﬁned by Article 4 (7) of the General Data
            Protection Regulation (hereinafter: GDPR) and other national
            protection laws is Mingo Ventures LLC. Mingo Ventures LLC is the con-
            troller and is responsible for your personal data (collectively
            referred to as
            <strong>“the Company”</strong>, <strong>“we”</strong>,
            <strong>“us” or “our” </strong>in this policy).
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Contact details</h6>
          <p className="mb-4"><strong>&nbsp;</strong>Our full details are:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Mingo Ventures LLC</li>
            <li>Name or title of DPO OR data privacy manager</li>
            <li><a href="mailto:admin@mingoapps.com">admin@mingoapps.com</a></li>
            <li>
              First Floor, SVG Teachers Credit Union Uptown Building, Corner of
              James &amp; Middle Street Kingston O. St. Vincent &amp; the
              Grenadines.
            </li>
            <li>[Telephone number]</li>
          </ul>
          <h6 className="text-xl font-bold mt-8 mb-4">Collecting and using your Personal Data</h6>
          <h6 className="text-xl font-bold mt-8 mb-4">Types of Data Collected</h6>
          <p className="mb-4"><strong>Personal Data</strong></p>
          <p className="mb-4">
            While using our Services, we may ask you to provide us with certain
            personally identiﬁable in- formation that can be used to contact or
            identify you. Personally identiﬁable information may include, but is
            not limited to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Email address</li>
            <li>Usage Data</li>
          </ul>
          <h6 className="text-xl font-bold mt-8 mb-4">Usage Data</h6>
          <p className="mb-4">Usage Data is collected automatically when using the Services.</p>
          <p className="mb-4">
            Usage Data may include information such as your Device’s Internet
            Protocol address (for example, IP address), browser type, browser
            version, the pages of our Services that you visit, the time and date
            of your visit, the time spent on those pages, unique device identiﬁers
            and other diagnostic data.
          </p>
          <p className="mb-4">
            When you access the Services by or through a mobile device, we may
            collect certain information automatically, including, but not limited
            to, the type of mobile device you use, your mobile device unique ID,
            the IP address of your mobile device, your mobile operating system,
            the type of mobile Internet browser you use, unique device identiﬁers
            and other diagnostic data.
          </p>
          <p className="mb-4">
            We may also collect information that your browser sends whenever you
            visit our Services or when you access the Services by or through a
            mobile device.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Tracking Technology and Cookies</h6>
          <p className="mb-4">
            We use Cookies and similar tracking technology to track the activity
            on our platforms and to store certain information. Some of the
            technology used includes beacons, tags, and scripts to collect and
            track information, as well as to improve and analyze our Services. The
            technology we use may include:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Cookies or Browser Cookies: </strong>A cookie is a small ﬁle
              placed on your Device. You can in- struct your browser to refuse all
              Cookies or to indicate when a Cookie is being sent. However, if you
              do not accept Cookies, you may not be able to use some features of
              our Services. Unless you have adjusted your browser settings to
              refuse Cookies, our Services may use
            </li>
            <li>
              <strong>Web Beacons: </strong>Certain sections of
              our Services and emails may contain small electronic ﬁles known as
              web beacons (also referred to as clear gifs, pixel tags, and
              single-pixel gifs) that permit the Company, to track user activity
              and create useful statistics.
            </li>
          </ul>
          <p className="mb-4">
            Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies
            remain on your personal computer or mobile device when you are oﬄine,
            while Session Cookies are deleted as soon as you close your web
            browser. Learn more about cookies: <u>Cookies: What Do They Do?</u>.
          </p>
          <p className="mb-4">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Necessary / Essential Cookies</h6>
          <ul className="list-disc pl-6 space-y-2">
            <li>Type: Session Cookies</li>
            <li>Administered by: Mingo Ventures LLC</li>
            <li>
              Purpose: These Cookies are essential to provide you with services
              available through the website and to enable you to use some of its
              features. They help to authenticate users and prevent fraudulent use
              of user accounts. Without these Cookies, the services that you
              request may not be provided.
            </li>
          </ul>
          <h6 className="text-xl font-bold mt-8 mb-4">Cookies Policy / Notice Acceptance Cookies</h6>
          <ul className="list-disc pl-6 space-y-2">
            <li>Type: Persistent Cookies</li>
            <li>Administered by: Mingo Ventures LLC</li>
            <li>
              Purpose: These Cookies identify if users have accepted the use of
              Cookies on the web- site and/or mobile
            </li>
          </ul>
          <h6 className="text-xl font-bold mt-8 mb-4">Functionality Cookies</h6>
          <ul className="list-disc pl-6 space-y-2">
            <li>Type: Persistent Cookies</li>
            <li>Administered by: Mingo Ventures LLC</li>
            <li>
              Purpose: These Cookies allow us to remember and record choices you
              make when you use the website, such as remembering your login
              details or language preference. The purpose of these Cookies is to
              provide you with a more personal experience and to avoid you having
              to re-enter your preferences every time you use the app
            </li>
          </ul>
          <p className="mb-4">
            For more information about the Cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Use of Your Personal Data</h6>
          <p className="mb-4">The Company may use Personal Data for the following purposes:</p>
          <p className="mb-4">
            <strong>To provide and maintain our Services</strong>: including to
            monitor the usage of our Services.
          </p>
          <p className="mb-4">
            <strong>To manage your Account: </strong>to manage your registration
            as a user of the Services. The Personal Data you provide can give you
            access to diﬀerent functionalities within the Services that are
            available to you as a registered user.
          </p>
          <p className="mb-4">
            <strong>For the performance of a contract: </strong>the development,
            compliance and undertaking of the purchase contracts associated to our
            products, items or overall services. This in- cludes any other
            contract entered throughout your use of our Services.
          </p>
          <p className="mb-4">
            <strong>To contact you: </strong>To contact you by email, telephone
            calls, SMS and other equivalent forms of electronic communication,
            such as a mobile application push notiﬁcations communicating system
            and/or product updates.
          </p>
          <p className="mb-4">
            <strong>To provide you: </strong>with news, special oﬀers and general
            information about other goods, services and events which we oﬀer
            similar to those that you have already purchased and/or enquired
            about. You will be able to opt-out of this function.
          </p>
          <p className="mb-4">
            <strong>To manage your requests: </strong>To attend
            and manage your requests.
          </p>
          <p className="mb-4">
            <strong>For business transfers: </strong>We may use your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or any other sale or transfer of some or
            all of our assets. This could be due to an going concern or as part of
            bankruptcy, liquidation, or similar proceedings, in which Personal
            Data held by us about our users is among the assets transferred.
          </p>
          <p className="mb-4">
            <strong>For other purposes</strong>: We may use your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the eﬀectiveness of our promotional campaigns and to
            evaluate and improve our Services, products, marketing and user
            experience.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">
            We may share Your personal information in the following situations:
          </h6>
          <p className="mb-4">
            <strong>With Service Providers: </strong>We may share your personal
            information with Service Providers to monitor and analyze the use of
            our Services and to contact you.
          </p>
          <p className="mb-4">
            <strong>For business transfers: </strong>We may share or transfer your
            personal information in connection with, or during negotiations of,
            any merger, sale of Company assets, ﬁnancing, or acquisition of all or
            a portion of our business to another company.
          </p>
          <p className="mb-4">
            <strong>With Aﬃliates: </strong>We may share your information with our
            aﬃliates, in which case we will require those aﬃliates to honor this
            Privacy Policy. Aﬃliates include our parent company and any other
            subsidiaries, joint venture partners or other companies that we
            control or for part of our structure.
          </p>
          <p className="mb-4">
            <strong>With Business Partners: </strong>We may share your information
            with our business partners to oﬀer You certain products, services or
            promotions.
          </p>
          <p className="mb-4">
            <strong>With other users: </strong>when you share personal information
            or otherwise interact with other users, such information may be viewed
            by all users and may be publicly distributed outside. If you interact
            with other users or register through a Third-Party, your contacts on
            the Third-Party’s platform may see your name, proﬁle, pictures and
            description of your activity. Similarly, other users will be able to
            view descriptions of your activity, communicate with you and view your
            proﬁle.
          </p>
          <p className="mb-4">
            <strong>With your consent</strong>: We may disclose your personal
            information for any other purpose with your consent.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Data security</h6>
          <p className="mb-4">
            All information you provide to us is stored on our secure servers. Any
            payment transactions caried out by us or our chosen third-party
            provider of payment processing services will be encrypted using
            Secured Sockets Layer technology. Where we have given you (or where
            you have chosen) a password that enables you to access certain parts
            of Our Sites, you are responsible for keeping this password
            conﬁdential. We ask you not to share a password with anyone.
          </p>
          <p className="mb-4">
            Once we have received your information, we will use strict procedures
            and security features to try to prevent your personal data from being
            accidentally lost, used or accessed in an unauthorised way.
          </p>
          <p className="mb-4">
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator when we
            are legally required to do so.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Retention of Your Personal Data</h6>
          <p className="mb-4">
            The Company will retain your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to retain
            your data to comply with applicable laws), resolve disputes, and
            enforce our legal agreements and policies.
          </p>
          <p className="mb-4">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of our Services, or we are legally obligated
            to retain this data for longer time periods.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Transfer of Your Personal Data</h6>
          <p className="mb-4">
            <strong>&nbsp;</strong>Your information, including Personal Data, is
            processed at the Company’s operating oﬃces and in any other places
            where the parties involved in the processing are located. This means
            that this information may be transferred to — and maintained on —
            computers located outside of your state, province, country or other
            governmental jurisdiction where the data protection laws may diﬀer
            than those from your jurisdiction.
          </p>
          <p className="mb-4">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p className="mb-4">
            The Company will take all steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organization or a country unless there are adequate controls in place
            including the security of your data and other personal information.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Disclosure of Your Personal Data</h6>
          <h6 className="text-xl font-bold mt-8 mb-4">Business Transactions</h6>
          <p className="mb-4">
            <strong>&nbsp;</strong>If the Company is involved in a merger,
            acquisition or asset sale, your Personal Data may be transferred. We
            will provide notice before your Personal Data is transferred and
            becomes subject to a diﬀerent Privacy Policy.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Law enforcement</h6>
          <p className="mb-4">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to valid
            requests by public authorities (e.g. a court or a government agency).
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Your legal rights</h6>
          <p className="mb-4">
            <strong>&nbsp;</strong>Under certain circumstances you have the
            following rights under data protection laws in relation to your
            personal data:
          </p>
          <p className="mb-4">
            <em
              >Request access to your personal data Request correction of your
              personal data Request erasure of your personal data Object to
              processing of your personal data</em
            >
          </p>
          <p className="mb-4">
            <em
              >Request restriction of processing your personal data Request
              transfer or your personal data</em
            >
          </p>
          <p className="mb-4"><em>Right to withdraw consent</em></p>
          <p className="mb-4">
            You also have the right to ask us not to continue to process your
            personal data for marketing purposes.
          </p>
          <p className="mb-4">
            You can exercise any of these rights at any time by contacting us at
            <a href="mailto:admin@mingoapps.com">admin@mingoapps.com</a>
          </p>
          <p className="mb-4">
            <strong>Request access </strong>to your personal data (commonly known
            as a “data subject access request”). This enables you to receive a
            copy of the personal data we hold about you and to check that we are
            lawfully processing it.
          </p>
          <p className="mb-4">
            <strong>Request correction </strong>of the personal data that we hold
            about you. This enables you to have any incomplete or inaccurate data
            we hold about you corrected, though we may need to verify the accuracy
            of the new data you provide to us.
          </p>
          <p className="mb-4">
            <strong>Request erasure </strong>of your personal data. This enables
            you to ask us to delete or remove personal data where there is no good
            reason for us continuing to process it. You also have the right to ask
            us to delete or remove your personal data where you have successfully
            exercised your right to object to processing (see below), where we may
            have processed your information unlawfully or where we are required to
            erase your personal data to comply with local law. Note, however, that
            we may not always be able to comply with your request of erasure for
            speciﬁc legal reasons which will be notiﬁed to you, if applicable, at
            the time of your request.
          </p>
          <p className="mb-4">
            <strong>Object to processing </strong>of your personal data where we
            are relying on a legitimate interest (or those of a third party) and
            there is something about your particular situation which makes you
            want to object to processing on this ground as you feel it impacts on
            your fundamental rights and freedoms. You also have the right to
            object where we are processing your personal data for
          </p>
          <p className="mb-4">
            direct marketing purposes. In some cases, we may demonstrate that we
            have compelling legitimate grounds to process your information which
            override your rights and freedoms.
          </p>
          <p className="mb-4">
            <strong>Request restriction of processing </strong>of your personal
            data. This enables you to ask us to suspend the processing of your
            personal data in the following scenarios:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>if you want us to establish the data’s accuracy;</li>
            <li>
              where our use of the data is unlawful but you do not want us to
              erase it;
            </li>
            <li>
              where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims; or
            </li>
            <li>
              you have objected to our use of your data but we need to verify
              whether we have overriding legitimate grounds to use
            </li>
          </ul>
          <p className="mb-4">
            <strong>Request the transfer </strong>of your personal data to you or
            to a third party. We will provide to you, or a third party you have
            chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
          </p>
          <p className="mb-4">
            <strong>Withdraw consent at any time </strong>where we are relying on
            consent to process your personal data. However, this will not aﬀect
            the lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is the
            case at the time you withdraw your consent.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Links to Other Websites</h6>
          <p className="mb-4">
            Our Services may contain links to other websites that are not operated
            by Mingo Ventures LLC. If you click on a Third-Party link, you will be
            directed to their website. Mingo Ventures LLC will not be held liable
            for issues arising from a Third-Party website and we strongly advise
            you re- view their Privacy Policy at your discretion.
          </p>
          <p className="mb-4">
            We have no control over and assume no responsibility for the content,
            privacy policies or practices of any Third-Party websites or services.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Changes to this Privacy Policy</h6>
          <p className="mb-4">
            We may update Our Privacy Policy from time to time. We will notify you
            of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="mb-4">
            We will let you know via email and/or a prominent notice on our
            Services, prior to the change becoming eﬀective and update the “Last
            updated” date at the top of this Privacy Policy.
          </p>
          <p className="mb-4">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are eﬀective when they are
            posted on this page.
          </p>
          <h6 className="text-xl font-bold mt-8 mb-4">Contact Us</h6>
          <p className="mb-4">
            If you have any questions about this Privacy Policy, you can contact
            us:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <b>By email: &nbsp;</b>
              <a href="mailto:admin@mingo.com">admin@mingo.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Privacy